:root {
    --control-background: #333333cc;
    --background-1: #222;
    --background-2: #111;
    --background-3: #151515;
    --background-4: #282828;
    --background-5: #555;
    --background-6: rgba(255, 255, 255, 0.5);
    --background-7: rgba(0, 0, 0, 0.6);
    --background-8: #383838;
    --background-9: #1a1a1a;
    --border-color: #3a3a3a;
    --color-1: #ccc;
    --color-2: #777;
    --color-3: #999;
}

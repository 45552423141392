.room {
    padding-left: 30px;
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.leave-room {
    position: absolute;
    bottom: 0;
    right: 7px;
    font-size: 25px;
    &:not([disabled]) {
        cursor: pointer;
    }
}

#room-chat-hide-button {
    position: absolute;
    top: 0;
    right: 7px;
    font-size: 25px;
    &:not([disabled]) {
        cursor: pointer;
    }
}

#room-full-message {
    position: absolute;
    left: 50%;
    margin-left: -109px !important;
    background: var(--background-1);
    border: var(--border-color) 1px solid;
    border-radius: 0.75rem;
    font-size: 18px;
    opacity: 0;
    top: -65px;
    transition: top linear 0.5s, opacity linear 0.5s;
    &.show {
        opacity: 1;
        top: 0px;
    }
}

.table-seats-8 {
    margin-right: 50px;
    margin-bottom: 50px;
    width: 100px;
    height: 40px;
    background: var(--background-5);
    border-radius: 0.75rem;
    position: relative;
    cursor: pointer;

    .seat {
        cursor: pointer;

        &:hover:before {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: var(--background-6);
            border-radius: 7px;
        }

        &.active:before {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: var(--background-7);
            border-radius: 7px;
        }
    }

    .room-name {
        font-size: 20px;
        height: auto;
        width: 100%;
    }

    &:hover .room-name {
        visibility: visible;
    }

    * {
        position: relative;
        font-size: 25px;
        height: 25px;
    }

    *::before {
        vertical-align: 0.125em;
    }

    .people {
        display: flex;
        :first-child {
            top: -25px;
            left: 6px;
        }

        :nth-child(2) {
            top: -25px;
            left: 12px;
        }

        :nth-child(3) {
            top: -25px;
            left: 18px;
        }

        :nth-child(4) {
            top: 6px;
            left: 25px;
            transform: rotate(90deg);
        }

        :nth-child(5) {
            top: 40px;
            left: -32px;
            transform: rotate(180deg);
        }

        :nth-child(6) {
            top: 40px;
            left: -88px;
            transform: rotate(180deg);
        }

        :nth-child(7) {
            top: 40px;
            left: -144px;
            transform: rotate(180deg);
        }

        :nth-child(8) {
            top: 6px;
            left: -200px;
            transform: rotate(270deg);
        }
    }
}

#chat-container {
    position: fixed;
    width: inherit;
    bottom: 0px;
    height: 40vh;
    min-height: 300px;
    $border: 1px solid var(--border-color);
    .room-chat-window {
        z-index: 20;
        height: calc(max(40vh, 300px) - 45px);
        margin-left: 1px;
        border-right: $border;
        border-top: $border;
        padding-bottom: 1px;
        .room {
            height: 100%;
        }
        .chat {
            height: 100%;
            #chat-message-container {
                height: calc(max(40vh, 300px) - 45px - 38px);
                flex-direction: column-reverse;
                display: flex;
                overflow-y: scroll;
                box-shadow: inset -5px 2px 5px var(--border-color), inset -5px -2px 5px var(--border-color);
                background-color: var(--background-3);
                padding-bottom: 5px;
                [id^="chat-message-"] {
                    border-radius: 0.25rem;
                    width: 70%;
                    margin: 0.25rem;
                    padding: 4px 7px;
                    flex-direction: column;
                    display: flex;
                    --background-color: var(--background-4);
                    background-color: var(--background-color);
                    box-shadow: 0px 0px 10px var(--background-color);
                    &.myself {
                        --background-color: var(--background-8);
                        margin-left: auto;
                        .info {
                            direction: rtl;
                        }
                        .text {
                            margin-left: auto !important;
                        }
                    }
                    .info {
                        display: inline;
                        white-space: nowrap;
                        color: var(--color-2);
                        font-size: 12px;
                        .user {
                            font-size: 14px;
                            color: var(--color-1);
                        }
                    }
                }
            }
            #chat_message-form {
                margin-right: 1px;
            }
        }
    }

    footer {
        z-index: 20;
        border-right: $border;
        border-top: $border;
        padding-top: 1px;
    }
}

#organizer-chat {
    height: 100vh;
    .room-chat-window {
        height: calc(100vh - 42px);
        .room {
            height: 100%;
        }
        .chat {
            height: 100%;
            #chat-message-container {
                height: calc(100vh - 45px - 38px);
                flex-direction: column-reverse;
                display: flex;
                overflow-y: scroll;
                box-shadow: inset -5px 2px 5px var(--border-color), inset -5px -2px 5px var(--border-color);
                background-color: var(--background-3);
                padding-bottom: 5px;
                [id^="chat-message-"] {
                    border-radius: 0.25rem;
                    width: 70%;
                    margin: 0.25rem;
                    padding: 4px 7px;
                    flex-direction: column;
                    display: flex;
                    --background-color: var(--background-4);
                    background-color: var(--background-color);
                    box-shadow: 0px 0px 10px var(--background-color);
                    &.myself {
                        --background-color: var(--background-8);
                        margin-left: auto;
                        .info {
                            direction: rtl;
                        }
                        .text {
                            margin-left: auto !important;
                        }
                    }
                    .info {
                        display: inline;
                        white-space: nowrap;
                        color: var(--color-2);
                        font-size: 12px;
                        .user {
                            font-size: 14px;
                            color: var(--color-1);
                        }
                    }
                }
            }
            #chat_message-form {
                margin-right: 1px;
            }
        }
    }
}

.nav-tabs .nav-link:hover {
    background-color: var(--background-2);
}

::-webkit-scrollbar {
    width: 10px;
    background-color: var(--background-2);
}

::-webkit-scrollbar-thumb {
    background-color: var(--background-1);
}

:root {
    --control-background: #555555aa;
    --background-1: #ccc;
    --background-2: #ddd;
    --background-3: #fafafa;
    --background-4: #dfdfdf;
    --background-5: #aaa;
    --background-6: rgba(0, 0, 0, 0.5);
    --background-7: rgba(255, 255, 255, 0.6);
    --background-8: var(--background-1);
    --background-9: #f5f5f5;
    --border-color: #dee2e6;
    --color-1: #555;
    --color-2: #888;
    --color-3: #ccc;
}
$input-bg: var(--background-3);
$input-color: var(--color-1);
$input-border-color: var(--border-color);
$input-focus-bg: var(--background-2);
$input-focus-color: var(--color-1);
$input-focus-border-color: var(--border-color);
$input-group-addon-bg: var(--background-3);
$input-group-addon-color: var(--color-1);
$input-group-addon-border-color: var(--border-color);
$nav-tabs-link-active-bg: var(--background-2);
$nav-tabs-link-active-color: var(--color-1);
$nav-tabs-border-color: var(--border-color);
$nav-tabs-link-active-border-color: var(--border-color);
$nav-tabs-link-hover-border-color: var(--border-color);
$modal-content-bg: var(--background-2);
$modal-content-border-color: var(--border-color);
$modal-content-color: var(--color-1);
$modal-header-border-color: var(--border-color);
$modal-footer-border-color: var(--border-color);
$border-color: var(--border-color);
$card-bg: var(--background-8);
$colors: (
    "white": var(--background-3),
    "gray": var(--background-1),
    "gray-dark": var(--background-2),
);

@import "../node_modules/bootstrap/scss/functions";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "../node_modules/bootstrap/scss/variables";
@import "./variables_light";
@media (prefers-color-scheme: dark) {
    @import "./variables_dark";
}
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/utilities/api";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/close";

@import "./room.scss";

html {
    height: 100%;
}

body {
    background: var(--background-3);
    color: var(--color-1);
    height: 100%;
}

main {
    height: 100%;
    & > .phx-connected {
        height: 100%;
    }
}

footer {
    background: var(--background-9);
    box-shadow: var(--background-7) 0px 5px 15px;
}

.input-group-prepend {
    display: flex;
    width: 100%;
}

[disabled] {
    color: var(--color-1);
    opacity: 0.6;
    .nav-link {
        cursor: not-allowed;
    }
}

.ml-a {
    margin-left: auto;
}

.fill-space {
    position: absolute;
    top: 0.5rem;
    height: 1em;
    width: 1em;
}

.texthover .text {
    visibility: hidden;
    background-color: var(--background-1);
    text-align: center;
    border-radius: 0.25rem;
    padding: 5px;
    position: absolute;
    z-index: 1;
    &.allign-right {
        right: 0;
    }
}

.texthover:not([disabled]).hover .text,
.texthover:not([disabled]).hover-event:hover .text {
    visibility: visible;
}

.fz-12 {
    font-size: 12px;
}

.fz-10 {
    font-size: 10px;
}

.fz-16 {
    font-size: 16px;
}

.fz-20 {
    font-size: 20px;
}

.fz-25 {
    font-size: 25px;
}

.event_list {
    display: flex;
    li {
        cursor: pointer;
        margin: 7px;
        padding: 0px 7px;
        display: flex;
        flex-direction: column;
        border-radius: 0.25rem;
        border: 1px solid var(--border-color);
        label {
            cursor: pointer;
        }
    }
}

#player {
    position: fixed;
    height: calc(100vh - max(40vh, 300px));
    width: inherit;
    z-index: 99;
    transition: height 0.4s linear, opacity 0.4s linear;

    &[data-fullscreen="true"] {
        height: 100%;
        width: 100%;
    }

    .stream_view {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .yt-player-control {
        top: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 100;
        overflow: hidden;
        &:hover {
            .control-row {
                bottom: 0;
            }
        }

        .control-row {
            position: absolute;
            bottom: -36px;
            transition: ease-in bottom 0.25s;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-direction: row-reverse;
            background: var(--control-background);

            * {
                cursor: pointer;
            }

            [class*="bi-"] {
                margin: 0 0.5em;
                font-size: 24px;
                color: var(--color-3);
            }

            .audio-bar {
                --volume: attr(data-volume %);
                float: right;
                position: relative;
                height: 0.5rem;
                border-radius: 0.25rem;
                width: 100px;
                border: 1px solid var(--color-1);

                .audio-bar-fill {
                    background: var(--color-3);
                    width: 0;
                    left: -1px;
                    height: 120%;
                    position: relative;
                    border-radius: 0.25rem;

                    .bi-record-fill {
                        position: absolute;
                        top: -1rem;
                        right: -0.75rem;
                        margin: 0;
                    }
                }
            }
        }
    }
}

#whereby-video {
    position: fixed;
    height: 100vh;
    width: 600px;
    right: 0;
    top: 0;
    background-color: var(--background-5);
    transition: height 0.4s linear, width 0.4s linear;

    &.large {
        width: 100%;
        height: calc(100vh - max(40vh, 300px));
        #open-room-chat-whereby {
            visibility: visible;
        }
    }
}

#open-room-chat {
    display: none;
}

#fullscreen-whereby {
    position: absolute;
    top: 0;
    right: calc(100% - 24px);
    font-size: 24px;
    transition: transform 0.2s linear, right 0.2s linear;
}

#some-junk {
    width: 100%;
    transition: width 0.4s ease-in-out;
}

.whereby_loaded {
    width: calc(100% - 600px) !important;
}

#open-room-chat-whereby {
    display: none;
    visibility: hidden;
    position: absolute;
    bottom: 0;
    right: 7px;
    font-size: 24px;
}

.flex {
    display: flex;
}
